import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“Hug a Tree” is a standing meditation that helps you learn to relax your muscles, release your joints, and stretch your spine.`}</p>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <p>{`Stand with your feet hip-width apart, toes pointing straight ahead, hands hanging at your sides.`}</p>
    <p>{`Imagine that the top of your head is attached to the ceiling by a thread. Feel your head being pulled upward gently, as if you were hanging by the thread. Everything else in your body is hanging downward.`}</p>
    <p>{`Release your shoulders, feel them pulling down, stretching the tendons on either side of your neck.`}</p>
    <p>{`Release your lower back. Your tailbone and pelvis pull downward, gently stretching and elongating your spine.`}</p>
    <p>{`Feel your weight settle into your legs. Keep your knees slightly bent, and your hips loose, so that you have a feeling like you’re about to sit on the edge of a table.`}</p>
    <p>{`Breathe freely and naturally, deep into your belly.`}</p>
    <p>{`Now raise your arms up, as if hugging a large balloon. Keep your shoulders and elbows released and drooping downward as you do this.`}</p>
    <h2 {...{
      "id": "exercise"
    }}>{`Exercise`}</h2>
    <p><strong parentName="p">{`Continue to breathe deeply and feel your head gently pulling up, as everything else releases and settles down.`}</strong></p>
    <p>{`Stand this way for 5 minutes to start (It’s harder than it sounds at first :) )`}</p>
    <p>{`Eventually, you can increase the time you stand to 10, 15, or 20 minutes as you get better and relaxing, and your legs become stronger.`}</p>
    <h2 {...{
      "id": "how-to-avoid-common-mistakes"
    }}>{`How to Avoid Common Mistakes`}</h2>
    <p>{`Keep your knees in line with the direction your toes are pointing. They should not collapse inward or outward.`}</p>
    <p>{`Keep your body upright. A common mistake is leaning backward when dropping the tailbone. Instead, allow your hips to flex as if sitting on the edge of a table.`}</p>
    <p>{`Keep your weight settled into your feet evenly. Not too far toward the toes, nor the heel. Not favoring the outside or the inside edge. Each foot should be flat on the ground.`}</p>
    <p>{`Keep your elbows and shoulders drooping downward. The weight of your arms is supported by your back, which is supported by your legs, which are rooted in the ground.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-1/"
      }}>{`Back to Lesson 1`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      